<template>
  <item-form :is-visible="form_visibility">
    <template #hide-trigger>
      <button class="p-2 px-4 bg-yellow-400 form__trigger-hide" @click="hide()">
        <fa-icon icon="times"></fa-icon>
      </button>
    </template>

    <template #default>
      <p class="mb-6 text-xl uppercase tracking-wider">formulir periode</p>
      <form @submit.prevent="submit()" class="grid grid-cols-1 gap-2">
        <!-- period name -->
        <div class="">
          <label for="period-name">Periode</label>
          <input type="text" id="period-name" v-model="form.name" />
        </div>

        <!-- period start -->
        <div>
          <label for="period-start">Mulai</label>
          <datetime
            id="period-start"
            title="Mulai"
            placeholder="Periode Mulai"
            class="custom-datepicker max-w-full"
            :use12-hour="false"
            :minute-step="10"
            :min-datetime="new Date().toISOString()"
            v-model="form.started_at"
            :auto="true"
          ></datetime>
        </div>

        <!-- period end -->
        <div>
          <label for="period-end">Berakhir</label>
          <datetime
            id="period-end"
            title="Berakhir"
            placeholder="Periode Berakhir"
            class="custom-datepicker"
            :use12-hour="false"
            :minute-step="10"
            :min-datetime="form.started_at"
            v-model="form.ended_at"
            :auto="true"
          ></datetime>
        </div>

        <div class="">
          <button class="button mt-6 w-full --primary">
            <fa-icon icon="check"></fa-icon>
            <span class="ml-2">Simpan</span>
          </button>
        </div>
      </form>
    </template>
  </item-form>
</template>



<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";

import { mapActions, mapGetters, mapMutations } from "vuex";
import _clone from "lodash/clone";

export default {
  data() {
    return {
      form: {
        id: null,
        name: null,
        started_at: null,
        ended_at: null,
      },
      formDefault: {},
    };
  },
  methods: {
    hide() {
      this.HIDE_FORM();
      this.UNSET_PERIOD_FORM_DATA();
      this.form = _clone(this.formDefault);
    },
    async submit() {
      // store when id is null
      if (this.form_data === null) {
        await this.storePeriod(this.form);
      } else {
        await this.updatePeriod(this.form);
      }
      this.hide();
    },
    ...mapActions("period", ["storePeriod", "updatePeriod"]),
    ...mapMutations("period", ["HIDE_FORM", "UNSET_PERIOD_FORM_DATA"]),
  },
  computed: {
    ...mapGetters("period", ["form_visibility", "form_action", "form_data"]),
  },
  watch: {
    form_data(val) {
      this.form = val === null ? _clone(this.formDefault) : _clone(val);
    },
  },
  mounted() {
    this.formDefault = _clone(this.form);
    Settings.defaultLocale = "id";
  },
  components: {
    ItemForm: () => import("@/components/Common/ItemForm.vue"),
    Datetime,
  },
  name: "PeriodForm",
};
</script>